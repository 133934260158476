<template>
  <div>
    <vx-card
      ref="filterCard"
      :title="$t('Filters')"
      class="user-list-filters mb-8 p-4"
      collapse-action

    >
      <div class="vx-row">
        <template v-for="field in filterFields">
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4" :key="field.name">
            <label class="text-sm opacity-75">{{ $t(field.label) }}</label>
            <vs-input
              v-if="!field.isDropdown"
              v-model="search[field.model]"
              class="w-full"
              :name="field.name"
            />

          </div>
        </template>

        <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("OwnerType") }}</label>
          <v-select
            label="nameAr"
            class="mt-2 w-full"
            name="nameAr"
            v-model="search.unitOwnerTypeId"
            :reduce="(OwnerType) => OwnerType.id"
            :options="ownerTypeOptions"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("UnitModel") }}</label>
          <v-select
            label="nameAr"
            class="mt-2 w-full"
            name="nameAr"
            v-model="search.unitModelId"
            :reduce="(unitModel) => unitModel.id"
            :options="unitModelOptions"
          />
        </div> -->


        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            color="warning"
            @click="handleSearch"
            >{{ $t("Search") }}</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="AdsManagementdata"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >


          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  AdsManagementdata.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : AdsManagementdata.length
                }}
                of {{ AdsManagementdata.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("Name") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("Address") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="IdeNumber">{{ $t("IdeNumber") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;" sort-key="Status">{{ $t("Status") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Action") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
            >
              <vs-td>
                <p style="font-size: 16px;font-family:almarai ;">
                  {{ tr.name }}
                </p>
              </vs-td>
              <vs-td>
                <p style="font-size: 16px;font-family:almarai ;">
                  {{ tr.permanentAddress }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.nationalCardNumber }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.phoneNumber }}
                </p>
                <p class="product-name font-medium truncate">
                  {{ tr.additionalPhoneNumber }}
                </p>
              </vs-td>

              <vs-td v-if="tr.isApproved == 1" >
                <span
                :style="{
                  backgroundColor: '#28C76F', // Green color for success status
                  padding: '8px 12px', // Adjust padding for better appearance
                  borderRadius: '20px', // Rounded corners
                  display: 'inline-block',
                  color: 'white',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                  transition: 'transform 0.2s ease-in-out', // Smooth transition
                  cursor: 'pointer', // Cursor pointer to indicate interactivity
                  position: 'relative' // Add position relative for pseudo-element
                }"
              >
                <!-- Checkmark icon -->

                {{ $t("عميل") }}
              </span>
              </vs-td>
              <vs-td v-else-if="tr.isApproved == 2">
                <span
                :style="{
                  backgroundColor: '#FFBF00', // Green color for success status
                  padding: '8px 12px', // Adjust padding for better appearance
                  borderRadius: '20px', // Rounded corners
                  display: 'inline-block',
                  color: 'white',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                  transition: 'transform 0.2s ease-in-out', // Smooth transition
                  cursor: 'pointer', // Cursor pointer to indicate interactivity
                  position: 'relative' // Add position relative for pseudo-element
                }"
              >
                <!-- Checkmark icon -->

                {{ $t("فني") }}
              </span>
              </vs-td>
              <vs-td v-else>
                <span
                :style="{
                  backgroundColor: '#6495ED', // Green color for success status
                  padding: '8px 12px', // Adjust padding for better appearance
                  borderRadius: '20px', // Rounded corners
                  display: 'inline-block',
                  color: 'white',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                  transition: 'transform 0.2s ease-in-out', // Smooth transition
                  cursor: 'pointer', // Cursor pointer to indicate interactivity
                  position: 'relative' // Add position relative for pseudo-element
                }"
              >
                <!-- Checkmark icon -->

                {{ $t("موظف") }}
              </span>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <router-link
                  :to="{
                    name: 'OwnerDetails',
                    params: { ID: tr.id },
                  }"
                >

                  <feather-icon
                    icon="EditIcon"
                    @click.stop="AddOrEditData(tr)"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>

                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                />
              </vs-td>

            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
// import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";

export default {
  components: {},
  data() {
    return {
      itemsPerPage: 10,
      search: {
        name: "",
        nationalId: "",
        phoneNumber: "",
        unitNumber: 0,
        unitOwnerTypeId: 0,
        unitModelId: 0,
        pageNumber: 0,
        pageSize: 20,
      },
      filterFields: [
        { label: "FullName", model: "name", name: "FullName" },
        { label: "IdeNumber", model: "nationalId", name: "IdeNumber" },
        { label: "PhoneNumber", model: "phoneNumber", name: "PhoneNumber" },
      ],
      // Updated static data with different statuses
      staticData: [
  {
    id: 1,
    name: "محمد أحمد",
    permanentAddress: "شارع الجمهورية، القاهرة",
    nationalCardNumber: "123456789",
    phoneNumber: "01012345678",
    additionalPhoneNumber: "",
    isApproved: 1, // Changed status to approved
  },
  {
    id: 2,
    name: "أميرة مصطفى",
    permanentAddress: "شارع رمسيس، الإسكندرية",
    nationalCardNumber: "987654321",
    phoneNumber: "01109876543",
    additionalPhoneNumber: "01109876544",
    isApproved: 2, // Changed status to technician
  },
  {
    id: 3,
    name: "فاطمة علي",
    permanentAddress: "شارع النيل، الأسيوط",
    nationalCardNumber: "456123789",
    phoneNumber: "01234567890",
    additionalPhoneNumber: "",
    isApproved: 3, // Changed status to employee
  },
  {
    id: 4,
    name: "مصطفى عبدالرحمن",
    permanentAddress: "شارع التحرير، الإسكندرية",
    nationalCardNumber: "789456123",
    phoneNumber: "01098765432",
    additionalPhoneNumber: "01098765433",
    isApproved: 1, // Changed status to approved
  },
  {
    id: 5,
    name: "إيمان حسن",
    permanentAddress: "شارع شبرا، القاهرة",
    nationalCardNumber: "321654987",
    phoneNumber: "01176543210",
    additionalPhoneNumber: "",
    isApproved: 2, // Changed status to technician
  },
  {
    id: 6,
    name: "أحمد محمود",
    permanentAddress: "شارع الطيران، الجيزة",
    nationalCardNumber: "789123456",
    phoneNumber: "01287654321",
    additionalPhoneNumber: "",
    isApproved: 3, // Changed status to employee
  },
  {
    id: 7,
    name: "سارة يوسف",
    permanentAddress: "شارع الأزهر، القاهرة",
    nationalCardNumber: "456789123",
    phoneNumber: "01056789123",
    additionalPhoneNumber: "01056789124",
    isApproved: 1, // Changed status to approved
  },
  {
    id: 8,
    name: "عليا عبدالله",
    permanentAddress: "شارع عبدالمنعم رياض، الإسكندرية",
    nationalCardNumber: "654321987",
    phoneNumber: "01134567890",
    additionalPhoneNumber: "",
    isApproved: 2, // Changed status to technician
  },
]

    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    AdsManagementdata() {
      // Use static data instead of data from store
      return this.staticData;
    },
  },
  methods: {
    // Other methods remain the same
  },
  created() {
    // Fetch data from store
    // if (!moduleAdsManagement.isRegistered) {
    //   this.$store.registerModule("AdsManagementList", moduleAdsManagement);
    //   moduleAdsManagement.isRegistered = true;
    // }
    // this.$store.dispatch("AdsManagementList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>


<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}
.toggle-switch {
position: relative;
display: inline-block;
width: 48px;
height: 24px;
}

.toggle-switch input {
display: none;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #28C76F;
-webkit-transition: .4s;
transition: .4s;
border-radius: 24px;
}

.slider:before {
position: absolute;
content: "";
height: 20px;
width: 20px;
left: 2px;
bottom: 2px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
border-radius: 50%;
}

input:checked + .slider {
background-color: #FF6347;
}

input:checked + .slider:before {
-webkit-transform: translateX(24px);
-ms-transform: translateX(24px);
transform: translateX(24px);
}
.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
